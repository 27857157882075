<template>
    <v-container fluid>
        <PageLayout>
            <div fluid @drop.prevent="dragover = false"
                  @dragover.prevent="dragover = true"
                  @dragenter.prevent="dragover = true"
                  @dragend.prevent="dragover = false">
                <TabNav />
                <div class="chat_container">
                    <div class="chat_left_top">
                        <v-toolbar
                            flat
                            elevation="2"
                            class="pt-1"
                            height="70"
                        >
                            <v-avatar size="40">
                                <img v-if="userAvatar !== '/img/avatar.png'" :src="userAvatar" :alt="userName">
                                <v-icon v-else color="primary" size="36">
                                    ${{'settingsIcon'}}
                                </v-icon>
                            </v-avatar>
                            <v-toolbar-title class="ml-3">{{ userName }}</v-toolbar-title>
                        </v-toolbar>
                    </div>
                    <div class="chat_right_top" v-if="this.chat_uuid !== null">
                        <v-toolbar
                            flat
                            elevation="2"
                            class="pt-1"
                            height="70"
                        >
                            <v-list subheader class="mb-0 pb-0">
                                <v-list-item>
                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                        <img v-if="chat_avatar !== '/img/avatar.png'" :src="chat_avatar" :alt="chat_name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{'settingsIcon'}}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="chat_name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="login_name"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>

                            <v-spacer></v-spacer>

                            <v-menu offset-y v-if="discuss_group_id">
                                <template v-slot:activator="{ on, attrs }" >
                                    <v-btn icon
                                           v-bind="attrs"
                                           v-on="on"

                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="(item, index) in discuss_chat_menu"  :key="index"
                                        @click="handleClickGroup(item.click)"
                                    >
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-toolbar>
                    </div>
                </div>
                <div class="chat_container" style="height:calc(100vh - 200px) !important;">
                    <div v-if="!add_chat && !add_group && !edit_group" class="chat_left_center">
                        <div class="d-flex ml-5 my-3">
                            <div class="mt-1">{{$t('chats')}}:</div>

                            <v-btn
                                @click="add_chat = true"
                                class="ml-auto mr-5"
                                small
                                color="primary"
                            >
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </div>
                        <v-list >
                            <v-list-item-group
                                v-model="my_chats"
                                active-class=""
                            >
                                <template v-for="(item, index) in discuss_chatItems">

                                    <v-list-item
                                        v-slot:default="{ active }"
                                        :key="item.chat.name + '-' + index"
                                        @click="openChat(item)"
                                    >


                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                            <img v-if="item.chat.avatar !== '/img/avatar.png'" :src="item.chat.avatar" :alt="item.chat.name">
                                            <v-icon v-else color="primary" size="22">
                                                ${{'settingsIcon'}}
                                            </v-icon>
                                        </v-list-item-avatar>


                                        <v-list-item-content>
                                            <v-list-item-title v-html="item.chat.name"></v-list-item-title>
                                            <v-list-item-subtitle v-if="item.last_message.type === 'text' || item.last_message.type === 'bot'" v-html="item.last_message.value"></v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="item.last_message.type === 'image'">
                                                {{$t('photo')}}
                                                <v-icon left x-small>
                                                    mdi-camera-outline
                                                </v-icon>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="item.last_message.type === 'file'">
                                                {{item.last_message.file_name}}
                                                <v-icon left x-small>
                                                    mdi-paperclip
                                                </v-icon>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider style="top:unset;position: unset"
                                        v-if="index < discuss_chatItems.length - 1"
                                        :key="index"
                                    ></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </div>
                    <div v-if="add_chat && !chat_group_name && !add_group && !edit_group" class="chat_left_center">
                        <v-list flat>
                            <v-list-item>
                                <v-list-item-icon >
                                    <v-icon @click="add_chat = false">mdi-arrow-left-thin</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('new_chat')"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list>

                        <v-list subheader two-line>
                            <v-list-item-group
                                v-model="chat_group"
                                :multiple="Array.isArray(chat_group)"
                                active-class=""
                            >
                            <v-list-item v-slot:default="{ active }">
                                <v-list-item-avatar class="border_blue ">
                                    <v-icon color="primary" size="22">
                                        mdi-account-group-outline
                                    </v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-html="$t('new_group')"></v-list-item-title>
                                 </v-list-item-content>
                            </v-list-item>
                            </v-list-item-group>
                        </v-list>

                        <v-list subheader two-line class="pb-16">
                            <v-list-item-group
                                v-model="contacts"
                                :multiple="chat_group.length > 0"
                                active-class=""
                            >
                            <template v-for="(item, index) in adminItems">
                                <v-subheader v-if="index === 0" v-text="$t('staff')"></v-subheader>

                                <v-list-item v-slot:default="{ active }" :key="item.title">

                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                        <img v-if="item.photo !== '/img/avatar.png'" :src="item.photo" :alt="item.name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{'settingsIcon'}}
                                        </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title v-html="item.name"></v-list-item-title>
                                        <v-list-item-subtitle v-html="formatPhoneNumber(item.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action v-if="chat_group.length > 0">
                                        <v-checkbox :input-value="active"></v-checkbox>
                                    </v-list-item-action>
                                </v-list-item>

                                <v-divider :key="index" inset style="position:unset"></v-divider>
                            </template>
                            </v-list-item-group>
                        </v-list>

                        <div v-if="typeof contacts !== 'undefined'" class="chat_button_create">
                            <v-btn
                                class="mx-2"
                                fab
                                dark
                                color="primary"
                                @click="chat_group_name=true"
                                v-if="Array.isArray(contacts) && contacts.length > 0"
                            >
                                <v-icon dark v-if="chat_group.length > 0">
                                    mdi-arrow-right-thin
                                </v-icon>
                            </v-btn>
                            <v-btn
                                class="mx-2"
                                fab
                                dark
                                color="primary"
                                v-if="!Array.isArray(contacts) && contacts !== ''"
                                @click="addChat()"
                            >

                                <v-icon dark>
                                    mdi-check-outline
                                </v-icon>
                            </v-btn>
                        </div>

                    </div>
                    <div v-if="add_chat && chat_group_name && !add_group && !edit_group" class="chat_left_center">
                        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                            <v-list flat>
                                <v-list-item>
                                    <v-list-item-icon >
                                        <v-icon @click="chat_group_name = false">mdi-arrow-left-thin</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="$t('new_group')"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                            </v-list>
                            <div>
                                <v-card elevation="0" class="mx-auto ">
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <make-group-avatar v-model="photo" aspect-ratio="1"/>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <ValidationProvider ref="name_group" rules="required|min:1|max:255"
                                                                    v-slot="{ errors, valid }">
                                                     <v-text-field v-model="name_group" type="text"

                                                                  :label="$t('name_group')"
                                                                  hide-details
                                                                  :error-messages="errors"
                                                                  :disabled="loading"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable>
                                                    </v-text-field>
                                                </ValidationProvider>

                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </div>

                            <div class="chat_button_create">

                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    color="primary"
                                    @click="addChatGroup()"
                                >

                                    <v-icon dark>
                                        mdi-check-outline
                                    </v-icon>
                                </v-btn>
                            </div>
                        </ValidationObserver>
                    </div>

                    <div v-if="add_group && !chat_group_name&& !edit_group" class="chat_left_center">
                        <v-list flat>
                            <v-list-item>
                                <v-list-item-icon >
                                    <v-icon @click="add_group = false">mdi-arrow-left-thin</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('add_group')"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list>


                        <v-list subheader two-line class="pb-16">
                            <v-list-item-group
                                v-model="add_users_group"
                                :multiple="true"
                                active-class=""
                            >
                                <template v-for="(item, index) in adminItems">
                                    <v-subheader v-if="index === 0" v-text="$t('staff')"></v-subheader>

                                    <v-list-item v-slot:default="{ active }" :key="item.title">

                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                            <img v-if="item.photo !== '/img/avatar.png'" :src="item.photo" :alt="item.name">
                                            <v-icon v-else color="primary" size="22">
                                                ${{'settingsIcon'}}
                                            </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title v-html="item.name"></v-list-item-title>
                                            <v-list-item-subtitle v-if="users_group_ids.indexOf(item.id) === -1" v-html="formatPhoneNumber(item.phone)"></v-list-item-subtitle>
                                            <v-list-item-subtitle v-else v-html="$t('already_added_group')"></v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action v-if="users_group_ids.indexOf(item.id) === -1">
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action>
                                    </v-list-item>

                                    <v-divider :key="index" inset style="position:unset"></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>

                        <div v-if="typeof add_users_group !== 'undefined'" class="chat_button_create">

                            <v-btn
                                class="mx-2"
                                fab
                                dark
                                color="primary"
                                v-if="Array.isArray(add_users_group) && add_users_group.length > 0"
                                @click="addUserGroup()"
                            >

                                <v-icon dark>
                                    mdi-check-outline
                                </v-icon>
                            </v-btn>
                        </div>

                    </div>
                    <div v-if="edit_group && !chat_group_name && !add_group" class="chat_left_center">
                        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                            <v-list flat>
                                <v-list-item>
                                    <v-list-item-icon >
                                        <v-icon @click="edit_group = false">mdi-arrow-left-thin</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="$t('edit_group')"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                            </v-list>
                            <div>
                                <v-card elevation="0" class="mx-auto ">
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <make-group-avatar v-model="photo" aspect-ratio="1"/>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <ValidationProvider ref="name_group" rules="required|min:1|max:255"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="name_group" type="text"

                                                                  :label="$t('name_group')"
                                                                  hide-details
                                                                  :error-messages="errors"
                                                                  :disabled="loading"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable>
                                                    </v-text-field>
                                                </ValidationProvider>

                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-list subheader two-line class="pb-16">
                                                    <v-list-item-group
                                                        v-model="contacts"
                                                        :multiple="true"
                                                        active-class=""
                                                    >
                                                        <template v-for="(item, index) in users_group">
                                                            <v-subheader v-if="index === 0" v-text="$t('participants')"></v-subheader>

                                                            <v-list-item v-slot:default="{ active }" :key="item.name">

                                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                                    <img v-if="item.photo !== '/img/avatar.png'" :src="item.photo" :alt="item.name">
                                                                    <v-icon v-else color="primary" size="22">
                                                                        ${{'settingsIcon'}}
                                                                    </v-icon>
                                                                </v-list-item-avatar>

                                                                <v-list-item-content>
                                                                    <v-list-item-title v-html="item.name"></v-list-item-title>
                                                                    <v-list-item-subtitle v-html="formatPhoneNumber(item.phone)"></v-list-item-subtitle>
                                                                </v-list-item-content>

                                                                <v-list-item-action >
                                                                    <v-btn
                                                                        elevation="0"
                                                                        icon
                                                                        @click="deleteUserGroup(item)"
                                                                    >
                                                                        <v-icon dark>
                                                                            mdi-delete-outline
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </v-list-item-action>
                                                            </v-list-item>

                                                            <v-divider :key="index" inset style="position:unset"></v-divider>
                                                        </template>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </div>

                            <div class="chat_button_create">

                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    color="primary"
                                    @click="editChatGroup()"
                                >

                                    <v-icon dark>
                                        mdi-check-outline
                                    </v-icon>
                                </v-btn>
                            </div>
                        </ValidationObserver>
                    </div>

                    <div class="chat_right_center"  ref="scrollContainerChatRightCenter">
                        <div class="chat_messages" ref="scrollContainer" >
                            <div class="center">
                                <v-col cols="12" >
                                <v-card class="box_shadow_block" elevation="0">
                                    <v-card-text class="px-0 mx-0">
                                        <v-row class="px-0 mx-0">
                                            <v-col cols="12" class="px-0 mx-0" v-if="chatMessagesDate.length !== 0">
                                                <v-card  id="book" flat  class="px-0 mx-0">
                                                    <v-card-text class="py-0 my-0 px-0 mx-0">
                                                        <div v-for="(chatMessages, date) in chatMessagesDate" :key="date">

                                                            <v-col v-for="msg in chatMessages" :key = "msg.id" class="py-0 my-0">
                                                                <div class="date_day_week" v-if="msg.comment.type === 'bot'">{{ msg.comment.value }}</div>

                                                                <v-card v-else :id="'div_'+msg.uuid" flat class="py-1 mt-7 px-3 mx-0" >


                                                                    <v-card class="d-flex"  flat >
                                                                        <div>
                                                                            <v-avatar size="36" class="border_blue px-0 mx-0">
                                                                                <img v-if="msg.photo !== '/img/avatar.png' && msg.photo !== '' && msg.photo !== null" :src="msg.photo" :alt="msg.name">
                                                                                <v-icon v-else color="primary" size="22">
                                                                                    ${{'settingsIcon'}}
                                                                                </v-icon>
                                                                            </v-avatar>
                                                                        </div>

                                                                        <div class="title_subtitle mr-auto px-2">
                                                                            <div>
                                                                                <span class="v-list-item__title">{{msg.name}}</span>
                                                                                <v-menu offset-y>
                                                                                    <template v-slot:activator="{ on, attrs }" >
                                                                                        <v-btn icon
                                                                                               v-bind="attrs"
                                                                                               v-on="on"
                                                                                               x-small
                                                                                        >
                                                                                            <v-icon>mdi-dots-vertical</v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                    <v-list>
                                                                                        <v-list-item
                                                                                            v-for="(item, index) in message_menu"  :key="index"
                                                                                            @click="handleClick(item.click, msg, date)"
                                                                                            :disabled="(item.click==='delete' && msg.me===false) ?  true : false"
                                                                                        >
                                                                                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                                                                                        </v-list-item>
                                                                                    </v-list>
                                                                                </v-menu>
                                                                            </div>
                                                                            <div class="v-list-item__subtitle">{{formatPhoneNumber(msg.phone)}}</div>
                                                                        </div>

                                                                        <div class="chat_time">
                                                                            <v-icon class="mx-0 px-0 pr-1 my-0 py-0" right>
                                                                                {{ msg.delivered ? 'mdi-check-all' : 'mdi-check'}}
                                                                            </v-icon>
                                                                            {{ $moment(msg.created_at).format('HH:mm') }}
                                                                        </div>
                                                                    </v-card>


                                                                    <v-row class="chat_text_row">
                                                                        <v-col cols="12" class="px-2 pb-1 " >
                                                                            <div v-if="msg.reply" class="pb-2">
                                                                                <div v-if="msg.reply.type === 'text'" class="rounded-lg rounded-r-0 py-2 d-inline-flex reply_background">
                                                                                    <div class="mr-auto pt-2 px-2">
                                                                                        <span class="reply_name">{{ msg.reply.name }}</span>
                                                                                        <span class="font-size-10 reply_name">{{ msg.reply.value }}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div v-if="msg.reply.type === 'image'" class="rounded-lg rounded-r-0 py-2 d-inline-flex reply_background">
                                                                                    <v-img v-if="msg.reply.type === 'image'"
                                                                                           class="my-0 py-0 ml-2"
                                                                                           max-width="50"
                                                                                           :src="msg.reply.value"
                                                                                    >
                                                                                    </v-img>
                                                                                    <div class="pt-2 px-2">
                                                                                        <span class="reply_name">{{ msg.reply.name }}</span>
                                                                                        <span class="reply_name font-size-10">
                                                                                            {{ $t('photo') }}
                                                                                            <v-icon left x-small>
                                                                                               mdi-camera-outline
                                                                                            </v-icon>
                                                                                        </span>
                                                                                    </div>

                                                                                </div>
                                                                                <div v-if="msg.reply.type === 'file'"  class="rounded-lg rounded-r-0 py-2 d-inline-flex reply_background">
                                                                                    <div class="mr-auto pt-2 px-2">
                                                                                        <span class="reply_name">{{ msg.reply.name }}</span>
                                                                                        <span class="reply_name font-size-10">
                                                                                            {{msg.reply.file_name}}
                                                                                            <v-icon left x-small>
                                                                                               mdi-paperclip
                                                                                            </v-icon>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div v-if="msg.reply.type === 'sound'"  class="rounded-lg rounded-r-0 py-2 d-inline-flex reply_background">
                                                                                    <div class="mr-auto pt-2 px-2">
                                                                                        <span class="reply_name">{{ msg.reply.name }}</span>
                                                                                        <span class="reply_name font-size-10 pt-1">
                                                                                                <av-waveform
                                                                                                    class="canvas_hide"
                                                                                                    :canv-width="0"
                                                                                                    :canv-height="0"
                                                                                                    :audio-src="msg.comment.value">
                                                                                                </av-waveform>
                                                                                                {{$t('record')}}
                                                                                                <v-icon left x-small>
                                                                                                  mdi-play-circle-outline
                                                                                                </v-icon>
                                                                                            </span>
                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div v-if="msg.comment.type === 'text'" class="chat_text">
                                                                                {{ msg.comment.value }}
                                                                            </div>
                                                                            <div v-if="msg.comment.type === 'image'" class="chat_text">
                                                                                <v-img
                                                                                    class="mb-3 cursor_pointer"
                                                                                    max-width="250"
                                                                                    :src="msg.comment.value"
                                                                                    @click="ImageView(msg)"
                                                                                >

                                                                                    <v-row v-if="msg.delivered ? false : true"
                                                                                           class="fill-height ma-0"
                                                                                           align="center"
                                                                                           justify="center"

                                                                                    >
                                                                                        <v-progress-circular
                                                                                            indeterminate
                                                                                            color="grey lighten-5"
                                                                                        ></v-progress-circular>
                                                                                    </v-row>
                                                                                </v-img>
                                                                            </div>
                                                                            <div v-if="msg.comment.type === 'file'" class="chat_text">
                                                                                <v-btn
                                                                                    color="primary"
                                                                                    class="white--text"
                                                                                    :href="msg.comment.value"
                                                                                    download
                                                                                >
                                                                                    <v-icon
                                                                                        left
                                                                                        dark
                                                                                    >
                                                                                        {{ !msg.delivered ? 'mdi-loading mdi-spin' : 'mdi-cloud-upload'}}
                                                                                    </v-icon>

                                                                                    {{ msg.comment.file_name }} ({{ (Number(msg.comment.size) / 1000).toFixed(1) + ' KB' }} )

                                                                                </v-btn>
                                                                            </div>
                                                                            <div v-if="msg.comment.type === 'sound'" class="chat_text">
                                                                                <av-waveform
                                                                                    class="canvas_hide"
                                                                                    :canv-width="0"
                                                                                    :canv-height="0"
                                                                                    :audio-src="msg.comment.value">
                                                                                </av-waveform>
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card>

                                                            </v-col>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            </div>
                        </div>
                        <div class="chat_input" v-if="this.chat_uuid !== null">
                           <div style="border-top: solid 1px #B3B3B3 !important;">
                               <v-card flat>
                                   <v-toolbar
                                       class="mb-2 toolbar_reply rounded-0"
                                       height="60px"
                                       elevation="0"
                                       v-if="toolbar_reply"
                                   >
                                       <div class="reply" >
                                           <div class="pt-0 my-0" v-if="reply_msg.type === 'text'">
                                               <span class="reply_name"> {{ reply_name}} </span>
                                               <span class="font-size-10">{{ reply_msg.value }}</span>
                                           </div>
                                           <div v-if="reply_msg.type === 'image'" class="d-flex mx-0 px-0">
                                               <v-img v-if="reply_msg.type === 'image'"
                                                      class="ml-1 my-0"
                                                      max-width="50"
                                                      :src="reply_msg.value"

                                               >
                                               </v-img>
                                               <div class="mr-auto pt-0 px-2">
                                                   <span class="reply_name">{{ reply_name}}</span>
                                                   <span class="reply_name font-size-10">
                                                                               {{$t('photo')}}
                                                                               <v-icon left x-small >
                                                                                   mdi-camera-outline
                                                                               </v-icon>
                                                                           </span>
                                               </div>




                                           </div>
                                           <div v-if="reply_msg.type === 'file'" class="my-0">
                                               <div class="pt-0">
                                                   <span class="reply_name">{{ reply_name}}</span>
                                                   <span class="font-size-10">
                                                                               {{reply_msg.file_name}}
                                                                               <v-icon left x-small>
                                                                                   mdi-paperclip
                                                                               </v-icon>
                                                                           </span>
                                               </div>



                                           </div>
                                           <div v-if="reply_msg.type === 'sound'" class="my-0">
                                               <div class="pt-0">
                                                   <span class="reply_name">{{ reply_name}}</span>
                                                   <span class="font-size-10">
                                                                                   {{$t('record')}}
                                                                                   <v-icon left x-small>
                                                                                       mdi-play-circle-outline
                                                                                   </v-icon>
                                                                               </span>
                                               </div>
                                           </div>
                                       </div>

                                       <v-spacer></v-spacer>
                                       <v-toolbar-items>
                                           <v-btn
                                               icon
                                               @click="replyClose"
                                           >
                                               <v-icon>mdi-close</v-icon>
                                           </v-btn>
                                       </v-toolbar-items>
                                   </v-toolbar>
                                   <v-textarea
                                       v-model="text"
                                       :label="$t('message')"
                                       no-details
                                       rows="1"
                                       auto-grow
                                       full-width
                                       id="chat-message-typer-textarea"
                                       @keyup.enter="handleEnterKey"
                                       :disabled="loading"
                                       hide-details
                                       class="input_textarea send_msg py-0 my-0"


                                   >
                                       <template v-slot:append>
                                           <v-icon  @click="document.getElementById('file').click()">mdi-paperclip</v-icon>
                                       </template>
                                       <template v-slot:append-outer>
                                           <v-icon  @click="SentMessage()" class="mr-6">mdi-send</v-icon>
                                       </template>



                                   </v-textarea>
                                   <v-file-input hide-input prepend-icon=""
                                                 v-model="files"
                                                 id="file"
                                                 multiple
                                                 @change="previewFiles"
                                   >
                                   </v-file-input>
                               </v-card>
                           </div>
                        </div>
                    </div>
                </div>


                <v-dialog
                    v-model="dialog_file"
                    scrollable
                    persistent
                    transition="dialog-bottom-transition"
                    max-width="500px"


                >
                    <v-card>
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('send_files', {'count': document_urls.length}) }}</span>
                            </div>
                            <div>
                                <v-icon @click="dialog_file = false;document_urls=[];documents=[]">mdi-close</v-icon>
                            </div>
                        </v-toolbar>

                        <v-card-text  style="height: 300px;" class="pa-0">
                            <v-list subheader>
                                <v-list-item  v-for="document in document_urls" :key="document.id">
                                    <v-list-item-icon>
                                        <v-img
                                            max-width="90"
                                            :alt="`${document.name}`"
                                            :src="document.url"
                                            max-height="60"
                                        ></v-img>
                                        <v-icon
                                            x-large
                                            v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title v-text="document.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-icon class="d-flex align-self-center mt-2">
                                        <v-btn
                                            icon
                                            @click="deleteFile(document)"
                                        >
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>

                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>


                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="SentMessageFile">
                                {{$t('sent')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-overlay :value="dragover && chat_uuid !== null" z-index="10" color="#244ba8">
                    <v-row>
                        <v-col style="height: 400px;width:500px;"
                               class="grey darken-1"
                               @drop.prevent="dropFile"
                        >
                            <v-col style="height: 380px;width:490px; border: dashed 4px #fff;">

                                <v-card
                                    height="350"
                                    class="pa-2 grey darken-1 align-self-center"
                                    elevation="0"

                                >
                                    <v-col sm="12" class="text-center mt-10">
                                        <v-icon
                                            left style="font-size: 150px"

                                            class="text-center">
                                            mdi-file-upload-outline
                                        </v-icon>
                                    </v-col>
                                    <v-col sm="12" class="text-center font-weight-bold h5">{{$t('drop_files_here_to_send_them')}}</v-col>


                                </v-card>


                            </v-col>
                        </v-col>
                    </v-row>
                </v-overlay>
            </div>
        </PageLayout>
    </v-container>
</template>
<script>

  import {ValidationObserver, ValidationProvider} from 'vee-validate'
  import {mapGetters} from "vuex"
  import MakeGroupAvatar from "../components/MakeGroupAvatar";
  import TabNav from "@/components/Leentech/TabsNav.vue";
  import PageLayout from "@/components/Leentech/PageLayout.vue";

    export default {
        name: "DiscussChats",
        components: {
            PageLayout,
            MakeGroupAvatar,
            ValidationProvider,
            ValidationObserver,
            TabNav
        },
        data() {
            return {
                discuss_chat: null,
                code: null,
                sortBy: "last_message_date",
                sortDir: false,
                loading: false,
                options: {},
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 7,
                totalDiscussChats: 0,
                discuss_chatItems: [],
                filter_show:false,
                text:null,
                chat_avatar: null,
                chat_name: null,
               // login_name:'+7 707 747 88 99, +7 755 889 88 55',
                login_name: null,
                chat_uuid: null,
                chatMessagesDate: [],
                loadingMessage: false,
                reply_msg: {},
                reply_name: null,
                reply_uuid: null,
                message_menu: [
                    { title: this.$t('delete'), click: "delete"},
                    { title: this.$t('reply'), click: "reply" },
                ],
                discuss_chat_menu: [
                    { title: this.$t('quit_band'), click: "quit_band"},
                    { title: this.$t('add_group'), click: "add_group" },
                    { title: this.$t('edit_group'), click: "edit_group" },
                ],
                toolbar_reply:false,
                add_chat: false,
                adminItems: [],
                contacts: [],
                chat_group: [],
                chat_group_name: false,
                photo: null,
                name_group: null,
                my_chats: [],
                document,
                documents:[],
                files:[],
                document_urls: [],
                dialog_file: false,
                dragover: false,
                add_group: false,
                edit_group: false,
                users_group: [],
                users_group_ids: [],
                add_users_group: [],
                discuss_group_id: null,

            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang', 'itemsPerPage', 'perPageItems', 'userName', 'userAvatar','timezone']),
            languages() {
                return this.listLanguages
            }
        },
        watch: {
            chat_group: {
                handler(newValue) {
                    this.contacts = [];
                }
            }
        },
        async mounted() {
            await this.getDiscussChats()
            await this.getAdmins()
            if(this.$route.params.uuid) {
           //     this.chat_uuid = this.$route.params.uuid;
                let item = this.discuss_chatItems.find(obj => obj.uuid === this.$route.params.uuid);
                this.openChat(item)
            }
            this.DiscussChatCommentsWebsocket()
        },
        methods: {

            async getDiscussChats() {
                var _this = this
                this.progress = 0
                this.loading = true

                let params = {}

                // if (page !== undefined) {
                //     params.page = page
                // }


                if (this.discuss_chat) {
                    params.discuss_chat = this.discuss_chat
                    params.page = 1
                }
                if (this.code) {
                    params.code = this.code
                    params.page = 1
                }

                await this.$http
                    .get("admin/discuss_chat", {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.discuss_chatItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalDiscussChats = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_list_discuss_chats'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            openChat(item){
                this.chat_uuid = item.uuid;
                this.chat_name = item.chat.name;
                this.login_name = item.admins_names;
                this.chat_avatar = item.chat.avatar;
                this.photo = item.chat.avatar;
                this.name_group = item.chat.name;
                this.users_group = item.admins;
                this.users_group_ids = item.admin_ids;
                this.discuss_group_id = item.discuss_group;
                this.getMessages();
            },
            handleEnterKey(event) {
                if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.altKey && !event.metaKey) {
                    this.SentMessage();
                }
                else{
                    event.preventDefault();
                    if(!event.shiftKey){
                        this.text += '\n';
                    }
                }
            },
            scrollCard(){
                this.$nextTick(() => {
                    // Прокрутка до нижней части после обновления списка
                    if( this.$refs.scrollContainer !== undefined)
                    {
                        const scrollContainer = this.$refs.scrollContainer;
                        scrollContainer.scrollTop = scrollContainer.scrollHeight;
                    }  // Прокрутка до нижней части после обновления списка
                    if( this.$refs.scrollContainerChatRightCenter !== undefined)
                    {
                        const scrollContainerChatRightCenter = this.$refs.scrollContainerChatRightCenter;
                        scrollContainerChatRightCenter.scrollTop = scrollContainerChatRightCenter.scrollHeight;
                    }
                });
            },
            async SentMessage() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()


                if( typeof this.text  === 'undefined' || this.text  === null || this.text === ''){
                    return;
                }
                let comment_uuid = this.$uuid.v4();
                formData.append('comment_uuid', comment_uuid)

                formData.append('chat_uuid', this.chat_uuid)

                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }
                if (this.reply_uuid) {
                    formData.append('reply_uuid', this.reply_uuid)
                }
                if (this.text) {
                    formData.append('message', this.text)
                }

                if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                    formData.append('id_array', 1)
                }
                else{
                    formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
                }

                // Add
                await this.$http
                    .post('admin/chat_comment', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        let replyMessage = {}
                        if (this.reply_uuid) {
                            if(this.reply_msg.type === 'text'){
                                replyMessage = {
                                    "type":  this.reply_msg.type,
                                    "name": this.reply_name,
                                    "reply_uuid":  this.reply_msg.uuid,
                                    "value": this.reply_msg.value,
                                }
                            }
                            else{
                                replyMessage = {
                                    "type":  this.reply_msg.type,
                                    "name": this.reply_name,
                                    "reply_uuid":  this.reply_msg.uuid,
                                    "value": this.reply_msg.value,
                                    "size": this.reply_msg.size,
                                    "type_mime": this.reply_msg.type_mime,
                                    "file_type": this.reply_msg.file_type,
                                    "file_name": this.reply_msg.file_name,
                                }
                            }

                        }

                        if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {

                            const newMessage = {
                                [this.$moment().format('YYYY-MM-DD')]:
                                    [
                                        {
                                            "id": Number(new Date().getTime()) + 1,
                                            "uuid": comment_uuid,
                                            "me": true,
                                            "comment": {"type": "text", "value": this.text},
                                            "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                                            "delivered": false,
                                            "name": this.$auth.user().name,
                                            "phone": this.$auth.user().phone,
                                            "photo": this.$auth.user().photo,
                                            "reply": replyMessage
                                        }
                                    ]
                            }
                            this.chatMessagesDate = { ...this.chatMessagesDate, ...newMessage };
                        }
                        else{
                            this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].push({
                                "id": Number(new Date().getTime()) + this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1,
                                "uuid": comment_uuid,
                                "me": true,
                                "comment": {"type": "text", "value": this.text},
                                "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                                "delivered": false,
                                "name": this.$auth.user().name,
                                "phone": this.$auth.user().phone,
                                "photo": this.$auth.user().photo,
                                "reply": replyMessage
                            });
                        }
                        this.text = null
                        this.scrollCard();
                        this.replyClose();
                        this.getDiscussChats();

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('message_could_not_be_sent'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },
            async getMessages() {
                this.loadingMessage = true;
                let params = {};
                params.chat_uuid = this.chat_uuid;

                await this.$http
                    .get("admin/chat_comment", {
                        params: params,
                    })
                    .then(res => {
                        if(res && res.body && res.body.data)
                        {
                            this.chatMessagesDate = res.body.data
                        }
                        else{
                            this.chatMessagesDate = []
                        }
                        this.scrollCard();
                        this.DiscussChatCommentsWebsocket();

                    })
                    .catch(err => {
                        this.chatMessagesDate = []
                        this.$toastr.error(this.$t('failed_to_get_list_messages'))
                    })
                    .finally(end => {
                        this.loadingMessage = false
                    })
            },
            DiscussChatCommentsWebsocket() {
                //console.log('ddddd' + `App.Models.Admin.${this.$auth.user().id}`)
                //  console.log('ddddd' + `.Event.DiscussChat.${this.chat_uuid}`)
                window.Echo
                    .private(`App.Models.Admin.${this.$auth.user().id}`)
                    .listen(`.Event.DiscussChat.${this.chat_uuid}`, (event) => {
                        //   console.log(event)
                        //Если пришло добавление собщение
                        if(event && event.id && event.id > 0) {
                            if (event.comment && event.comment.type) {
                                // if(event.comment.type === 'text') {

                                if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                                    const newMessage = {
                                        [this.$moment().format('YYYY-MM-DD')]:
                                            [
                                                event
                                            ]
                                    }
                                    this.chatMessagesDate = { ...this.chatMessagesDate, ...newMessage };
                                }
                                else{
                                    let message_date = this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')];
                                    let uuidToFind = event.uuid;
                                    let foundObject = message_date.find(obj => obj.uuid === uuidToFind);
                                    if (typeof foundObject != "undefined") {
                                        foundObject.id = event.id;
                                        foundObject.comment = event.comment;
                                        foundObject.delivered = event.delivered;
                                        foundObject.uuid = event.uuid;
                                        foundObject.created_at = event.created_at;
                                    }
                                    else{
                                        message_date.push(event);
                                    }
                                }
                            }

                        }
                        else if(event && event.type && event.type==='delete'){
                            //Если пришло удаление собщение
                            if (event.id_array && event.id_array > 0 && event.date) {
                                //if(event.admin_id !== this.$auth.user().id){
                                this.chatMessagesDate[event.date].splice(event.id_array, 1);
                                // }

                            }
                        }
                        this.scrollCard();
                        this.getDiscussChats()
                    })
                    .listen('.Event.Admin.Messages', async (event) => {
                        if (event.data.socket.indexOf('information') !== -1) {
                            await this.getDiscussChats();
                            let item = this.discuss_chatItems.find(obj => obj.uuid === event.data.params.uuid);
                            if(this.chat_uuid === item.uuid ){
                                this.openChat(item)
                            }

                            console.log(item);
                            console.log(event.data.params.uuid);
                        }
                    });
            },

            handleClickGroup(click) {
                switch (click) {
                    case 'add_group':
                        this.edit_group = false;
                        this.add_group = true;
                    break;
                    case 'edit_group' :
                        this.add_group = false;
                        this.edit_group = true;
                    break;
                    case 'quit_band' :
                        this.quitBand()

                    break;
                }
            },
            handleClick(click,msg, date) {
                switch (click) {
                    case 'delete':
                        this.deleteMessages(msg,date)
                        break;
                    case 'reply' :
                        this.replyOpen(msg)
                        break;
                }
            },
            replyOpen(msg){
                this.reply_uuid = msg.uuid
                this.reply_msg = msg.comment;
                this.reply_name = msg.name;
                this.toolbar_reply = true;
                this.scrollCard();
            },
            replyClose(){
                this.reply_uuid = null
                this.reply_name = null
                this.toolbar_reply = false
                this.reply_msg = {}
            },
            async deleteMessages(msg,date) {
                this.loading = true;
                let params = {};
                let idx = this.chatMessagesDate[date].indexOf(msg);
                params.chat_uuid = this.chat_uuid;
                params.message_uuid = msg.uuid;
                params.date = date;
                if (idx !== -1) {
                    params.id_array = idx;
                }

                await this.$http
                    .delete(`admin/chat_comment/${this.chat_uuid}`,{
                        params: params,
                    })
                    .then(res => {
                        if (idx !== -1) {
                            this.chatMessagesDate[date].splice(idx, 1);
                        }
                        this.$toastr.success(this.$t('message_has_been_deleted'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('message_has_not_been_deleted'))
                    })
                    .finally(end => {
                        this.loading = false
                    })

            },

            async getAdmins() {
                this.loading = true
                let params = {};
               // params.active = this.active
                params.filter = 'all_active'
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.adminItems = res.body.data
                    })
                    .catch(err => {
                        this.adminItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            findIndexByKey(array, key, value) {
                return array.findIndex(obj => obj[key] === value);
            },
            async addChat(){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()


                const admin = this.adminItems[this.contacts];

                if (admin.uuid ) {
                    formData.append('admin_uuid', admin.uuid)
                }

                await this.$http
                    .post('admin/discuss_chat', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('discuss_chat_has_been_create'))
                        this.getDiscussChats()
                        this.closeAddChat();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('discuss_chat_has_not_been_create'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },

            async addChatGroup(){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()


                if (this.contacts && this.contacts.length > 0) {
                    for (let i in this.contacts) {
                        if (this.adminItems[this.contacts[i]].uuid !== undefined && this.adminItems[this.contacts[i]].uuid !== '') {
                            formData.append(`admins[${i}]`, this.adminItems[this.contacts[i]].uuid)
                        }
                    }
                }

                if (this.name_group) {
                    formData.append('name_group', this.name_group)
                }

                if (this.photo) {
                    if (this.photo.length > 250) {
                        var mimeType = this.getMimeType(this.photo)
                        var blob = this.dataURL64toBlob(this.photo)
                        if (mimeType && blob) {
                            formData.append('photo', blob, mimeType)
                        }
                    } else {
                        formData.append('photo', this.photo)
                    }
                }


                await this.$http
                    .post('admin/discuss_chat/group', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('discuss_chat_has_been_create'))
                        this.getDiscussChats()
                        this.closeAddChat();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('discuss_chat_has_not_been_create'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            closeAddChat(){
                this.add_chat = false;
                this.chat_group = [];
                this.chat_group_name = false;
                this.photo = null;
                this.name_group = null;
                this.contacts = [];
                this.add_users_group = [];
                this.add_group = false;
                this.edit_group = false;


            },
            dropFile(event){
                this.files = Array.from(event.dataTransfer.files);
                this.previewFiles();
            },
            previewFiles(){
                let i = this.document_urls.length
                let data = {}
                this.files.forEach((file) => {
                    data = {}
                    data.id = i
                    data.url = URL.createObjectURL(file)
                    data.name = file.name
                    data.blob =  file
                    data.size = file.size
                    data.type = file.type
                    this.document_urls.push(data)
                    i++
                })
                this.dialog_file = true
            },
            deleteFile(item){
                let index =  this.document_urls.indexOf(item)
                this.document_urls.splice(index, 1)
                if(this.document_urls.length === 0){
                    this.dialog_file = false;
                    this.document_urls=[];
                    this.documents=[]
                }
            },
            async SentMessageFile() {
                var _this = this;
                let formData, blob;
                this.dialog_file = false;
                this.documents=[]
                let is_image = false;


                let id_array = 0;
                for (const file of this.document_urls) {

                    let replyMessage = {}
                    if (this.reply_uuid) {
                        if(this.reply_msg.type === 'text'){
                            replyMessage = {
                                "type":  this.reply_msg.type,
                                "name": this.reply_name,
                                "reply_uuid":  this.reply_msg.uuid,
                                "value": this.reply_msg.value,
                            }
                        }
                        else{
                            replyMessage = {
                                "type":  this.reply_msg.type,
                                "name": this.reply_name,
                                "reply_uuid":  this.reply_msg.uuid,
                                "value": this.reply_msg.value,
                                "size": this.reply_msg.size,
                                "type_mime": this.reply_msg.type_mime,
                                "file_type": this.reply_msg.file_type,
                                "file_name": this.reply_msg.file_name,
                            }
                        }

                    }
                    let comment_uuid = this.$uuid.v4();

                    is_image = this.mimeTypeImage(file.type);
                    if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                        id_array = 1;
                        const newMessage = {
                            [this.$moment().format('YYYY-MM-DD')]:
                                [
                                    {
                                        "id": Number(new Date().getTime()) + 1,
                                        "uuid": comment_uuid,
                                        "me": true,
                                        "comment": is_image ? {"type": "image", "value": file.url} : {"type": "file", "value": file.url, "file_name":file.name, "size":file.size},
                                        "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                                        "delivered": false,
                                        "name": this.$auth.user().name,
                                        "phone": this.$auth.user().phone,
                                        "photo": this.$auth.user().photo,
                                        "reply": replyMessage
                                    }
                                ]
                        }
                        this.chatMessagesDate = { ...this.chatMessagesDate, ...newMessage };
                    }
                    else{
                        id_array = this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1;
                        this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].push({
                            "id": Number(new Date().getTime()) + (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length) + 1,
                            "uuid": comment_uuid,
                            "me": true,
                            "comment": is_image ? {"type": "image", "value": file.url} : {"type": "file", "value": file.url, "file_name":file.name, "size":file.size},
                            "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                            "delivered": false,
                            "name": this.$auth.user().name,
                            "phone": this.$auth.user().phone,
                            "photo": this.$auth.user().photo,
                            "reply": replyMessage
                        });
                    }

                    formData = new FormData();
                    if (this.reply_uuid) {
                        formData.append('reply_uuid', this.reply_uuid)
                    }
                    formData.append('comment_uuid', comment_uuid)
                    formData.append('id_array', id_array)
                    formData.append('chat_uuid', this.chat_uuid)
                    formData.append('type_mime', file.type)
                    formData.append('size', file.size)
                    formData.append('file_name', file.name)
                    formData.append('is_image', is_image ? 1 : null)
                    file.blob =  await this.convertBlobToBase64(file.blob)
                    if (file.blob) {
                        blob = this.dataURL64toBlob(file.blob)
                        if (blob) {
                            formData.append('file', blob, file.type)
                        }
                    }

                    // Add
                    this.$http
                        .post('admin/chat_comment/file', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(res => {
                            var index = this.chatMessagesDate.length + 1
                            if (typeof this.chatMessagesDate[index] != "undefined") {
                                this.chatMessagesDate[index].delivered = true;
                            }
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('message_could_not_be_sent'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            var index = this.chatMessagesDate.length + 1
                            if (typeof this.chatMessagesDate[index] !== "undefined") {
                                this.chatMessagesDate[index].delivered = true;
                            }
                        })


                }
                this.scrollCard();
                this.replyClose();
                this.getDiscussChats();

                this.document_urls=[];
                this.count_messages++;
            },

            async addUserGroup(){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                formData.append('chat_uuid', this.chat_uuid);
                if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                    formData.append('id_array', 1);
                }
                else{
                    formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1);
                }

                if (this.add_users_group && this.add_users_group.length > 0) {
                    for (let i in this.add_users_group) {
                        if (this.adminItems[this.add_users_group[i]].uuid !== undefined && this.adminItems[this.add_users_group[i]].uuid !== '') {
                            formData.append(`admins[${i}]`, this.adminItems[this.add_users_group[i]].uuid)
                        }
                    }
                }

                await this.$http
                    .post('admin/discuss_chat/group/user', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('participant_has_been_added_to_group'))
                        this.getDiscussChats()
                        this.closeAddChat();
                        if(res && res.body && res.body.data && res.body.data.admins)
                        {
                            this.users_group =  res.body.data.admins;
                            this.users_group_ids =  res.body.data.admin_ids;
                            this.login_name =  res.body.data.admins_names;
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('participant_has_not_been_added_to_group'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async deleteUserGroup(user) {
                this.loading = true;
                let params = {};
                params.chat_uuid = this.chat_uuid;
                if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                    params.id_array =  1
                }
                else{
                    params.id_array =  this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1
                }

                await this.$http
                    .delete(`admin/discuss_chat/group/user/${user.uuid}`,{
                        params: params,
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('participant_has_been_remove_to_group'))
                        this.getDiscussChats()
                        if(res && res.body && res.body.data && res.body.data.admins)
                        {
                            this.users_group =  res.body.data.admins;
                            this.users_group_ids =  res.body.data.admin_ids;
                            this.login_name =  res.body.data.admins_names;
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('participant_has_not_been_remove_to_group'))
                    })
                    .finally(end => {
                        this.loading = false
                    })

            },
            async editChatGroup(){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if (this.name_group) {
                    formData.append('name_group', this.name_group)
                }

                if (this.photo) {
                    if (this.photo.length > 250) {
                        var mimeType = this.getMimeType(this.photo)
                        var blob = this.dataURL64toBlob(this.photo)
                        if (mimeType && blob) {
                            formData.append('photo', blob, mimeType)
                        }
                    } else {
                        formData.append('photo', this.photo)
                    }
                }
                if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                    formData.append('id_array', 1);
                }
                else{
                    formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1);
                }

                await this.$http
                    .put(`admin/discuss_chat/group/${this.chat_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('group_has_been_edited'))
                        this.getDiscussChats()
                        if(res && res.body && res.body.data && res.body.data.name)
                        {
                            this.chat_name = res.body.data.name;
                            this.chat_avatar = res.body.data.avatar;
                            this.photo = res.body.data.avatar;
                            this.name_group = res.body.data.name;
                        }

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('group_has_not_been_edited'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },

            async quitBand() {
                this.loading = true;
                let params = {};
                params.chat_uuid = this.chat_uuid;
                if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                    params.id_array =  1
                }
                else{
                    params.id_array =  this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1
                }
                await this.$http
                    .delete(`admin/discuss_chat/group/quit`,{
                        params: params,
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('you_left_group'))
                        this.getDiscussChats()
                        this.closeAddChat()
                        this.chat_uuid = null;
                        this.chatMessagesDate = [];


                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('you_left_not_group'))
                    })
                    .finally(end => {
                        this.loading = false
                    })

            },


        }
    }
</script>

<style>
.chat_container{
    display:flex;
    background-color: #f3f4f6;
}
.chat_left_top{
    width: 20% !important;
}
.chat_right_top{
    border-left: solid 2px #d2d2d2;
    overflow-x: hidden;
    height: 72px !important;
    flex: 1;
}
.chat_left_center{
    position: relative !important;
    width: 20% !important;
    overflow-y: auto;

}
.chat_right_center{
    flex:1;
    display:flex;
    flex-direction:column;
    position:relative;
    border-left:solid 2px #d2d2d2;
    overflow-x:hidden;
}
.chat_messages{
    flex: 1;
    overflow-y: auto;
    background-color: #fff;
    margin-bottom: 60px
}
.chat_input{
    position: absolute;
    bottom: 0;
    width: 100%;
    height:57px;
}
.chat_button_create2{
    position: absolute;
    bottom:10px;
    left: 50%;
    transform: translateX(-50%);
}
.chat_button_create{
    position: fixed;
    bottom:65px;
    z-index: 10;
    width: 20% !important;
    text-align: center;
}
</style>
